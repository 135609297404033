"use client"

import { useCreateQuote } from "@/modules/Quote/hooks/use-create-quote"
import { getQuote } from "@/shared/http"
import { Breed, BreedWeight, Pet, Utms } from "@/shared/types/global-store"
import { generatePetId, generateUserId, hasUtm } from "@/shared/utils/helpers"
import { trackLeadAttribution } from "@/shared/utils/tracking"
import { useInterpret, useSelector } from "@xstate/react"
import { isPast } from "date-fns"
import { usePathname, useRouter } from "next/navigation"
import { type PropsWithChildren, createContext, useContext } from "react"
import type { InterpreterFrom } from "xstate"

import { matchHasMultiplePets, matchHasRedirectToPrepackages, matchHasValidPet } from "./helpers"
import { subscriptionFlowMachine } from "./machine"

const FLOW_STATE_KEY = "flow-state"

export function clearState() {
  if (typeof window === "undefined") {
    return
  }

  localStorage.removeItem(FLOW_STATE_KEY)
}

export const GlobalStateContext = createContext<InterpreterFrom<
  typeof subscriptionFlowMachine
> | null>(null)

export const GlobalStateProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const pathname = usePathname()

  const { mutateAsync } = useCreateQuote()

  const redirect = (path: string) => {
    if (pathname === path) {
      return
    }
    // @ts-ignore string is not assignable to type NextRouter, but type NextRouter is not exported
    router?.push(path)
  }

  const subscriptionFlowService = useInterpret(
    subscriptionFlowMachine,
    {
      devTools: true,
      actions: {
        goToPetName: () => redirect("/pet-name"),
        goToPetBirthDate: () => redirect("/pet-birthdate"),
        goToPetSex: () => {
          return redirect("/pet-sex")
        },
        goToPetType: () => redirect("/pet-type"),
        goToPetBreed: () => redirect("/pet-breed"),
        goToTribe: () => redirect("/tribe"),
        goToOwnerAddress: () => redirect("/owner-address"),
        goToOwnerInformations: () => redirect("/owner-name"),
        goToPrepackages: () => redirect("/prepackages"),
        goToCoverage: () => redirect("/coverage"),
        goToModalities: () => redirect("/modalities"),
        goToPricing: () => redirect("/pricing"),
        goToTribeQuote: () => redirect("/tribe-quote"),
        goToOwnerBirthDate: () => redirect("/owner-birthdate"),
        goToOwnerMailAndPhone: () => redirect("/owner-mail-phone"),
        goToAttribitionQuestion: () => redirect("/attributionQuestion"),
        goToRecap: () => redirect("/recap"),
      },
      services: {
        createQuote: async (ctx) => {
          const params = new URLSearchParams(window?.location.search)
          const quoteIdFromParam = params.get("quote_id")
          const quoteIdFromRedirectEmail = quoteIdFromParam || localStorage.getItem("quoteId")
          if (quoteIdFromRedirectEmail) {
            try {
              // we get all the quote data from the quoteId but we need to update the machine to have a state that takes the quote data and put it in the context
              const quote = await getQuote(quoteIdFromRedirectEmail)
              // == Pets ==
              ctx.pets = []
              for (const pet of quote.data) {
                // = Breed =
                const {
                  pet_race: race,
                  pet_father_race: father_race,
                  pet_mother_race: mother_race,
                  pet_weight: weight,
                } = pet

                let breed: Breed = {
                  race: race,
                }
                if (race === "mixed_breed") {
                  if (father_race === "unknown" && mother_race === "unknown") {
                    breed = {
                      race: "mixed_breed",
                      weight: weight as BreedWeight,
                    }
                  } else {
                    breed = {
                      race: "mixed_breed",
                      father_race,
                      mother_race,
                    }
                  }
                }

                const { coverage, options } = pet
                const { pack, prevention, health_rate, surgery_rate } = options || {}
                //@ts-ignore
                const { health_limit, prevention_limit, surgery_limit } = coverage || {}
                const pricing = {
                  pack: pack as Pet["pricing"]["pack"],
                  health_limit,
                  health_rate,
                  surgery_rate,
                  rate: health_rate ? health_rate.replace(/%/g, "") : undefined,
                  prevention,
                  prevention_limit,
                  surgery_limit,
                }

                const petHasOptions = !!options
                const petHasCoverage = !!coverage
                const petHasValidPricing = petHasOptions && petHasCoverage

                const {
                  pet_sexe: sex,
                  pet_type: type,
                  pet_uuid_type: uuid_type,
                  pet_uuid: uuid,
                  pet_idx: idx,
                  pet_birthday: birthday,
                  pet_name: name,
                } = pet

                ctx.pets.push({
                  birthday,
                  breed,
                  health: { status: "healthy" },
                  id: generatePetId(),
                  idx,
                  isPetDraft: false,
                  isQuoteCustomized: petHasValidPricing,
                  name,
                  ...(petHasValidPricing && {
                    pricing,
                  }),
                  sex: sex as Pet["sex"],
                  type: type as Pet["type"],
                  uuid_type: uuid_type as Pet["uuid_type"],
                  uuid,
                  price: pet.original_price ? Number(pet.original_price) : Number(pet.price),
                } as unknown as Pet)
              }

              // == Owner ==
              const {
                address,
                owner_birthday: birthday,
                owner_birthcity: birthcity,
                owner_firstname: firstname,
                owner_lastname: lastname,
                owner_phone: phone,
                owner_email: email,
              } = quote.pet_owner

              ctx.owner = {
                address,
                birthday,
                birthcity,
                firstname,
                lastname,
                phone,
                email,
              }

              // == Meta ==
              ctx.meta.quote_id = quote.data[0]?.quote_id || quoteIdFromRedirectEmail
              ctx.meta.user_id = quote.data[0]?.user_id || generateUserId()
              ctx.meta.ab_tests = quote.data[0]?.ab_tests || { prepackages: { variant: "default" } }
              if (ctx.pets && ctx.pets.length === 1) {
                ctx.currentPetId = ctx.pets[0]?.id
              }
              ctx.meta.start_date = isPast(new Date(quote.data[0]?.start_date || ""))
                ? new Date()
                : quote.data[0]?.start_date
              ctx.meta.subscription_mode =
                (quote.data[0]?.subscription_mode as "ASAP" | "DEFERRED") || "DEFERRED"
              // == Marketing ==
              ctx.marketing = quote.data[0]?.marketing || {}

              return { data: { quote_id: quoteIdFromRedirectEmail } }
            } catch {
              return mutateAsync({
                quoteId: ctx.meta.quote_id,
              })
            }
          }
          return mutateAsync({
            quoteId: ctx.meta.quote_id,
          })
        },
        urlParamsHandler: () => {
          const utmsFromLocalStorage = localStorage.getItem("utmParams")
          const utmParams = utmsFromLocalStorage
            ? (JSON.parse(utmsFromLocalStorage) as Utms)
            : {
                utm_source: "",
                utm_medium: "",
                utm_campaign: "",
                utm_content: "",
                utm_term: "",
                utm_comparateur: "",
                gclid: "",
                compclid: "",
                at_gd: "",
                advertiser_id: "",
                channel_id: "",
                market: "",
                click_id: "",
                click_ref: "",
                s_id: "",
                affiliate_gclid: "",
              }
          const referrer = localStorage.getItem("referrer") || ""
          if (hasUtm(utmParams) || referrer) {
            if (Boolean(utmParams.compclid)) {
              trackLeadAttribution(utmParams.compclid)
            }
          }
          return Promise.resolve()
        },
      },
      guards: {
        hasQuoteId: (ctx) => Boolean(ctx.meta.quote_id),
        hasMultiplePets: (ctx) => matchHasMultiplePets(ctx.pets),
        isAllPetsPriced: (ctx) => ctx.pets.every((pet) => pet.pricing),
        hastValidPets: (ctx) => matchHasValidPet(ctx.pets),
        hasToRedirectToPrepackages: (ctx) =>
          matchHasValidPet(ctx.pets) && matchHasRedirectToPrepackages(),
        hasNoBreedFromLp: (ctx) =>
          Boolean(!ctx.pets.find((pet) => pet.id === ctx.currentPetId)?.breed?.race),
        hasNoBreedAndNoPetNameFromLp: (ctx) =>
          Boolean(
            !ctx.pets.find((pet) => pet.id === ctx.currentPetId)?.breed?.race &&
              !ctx.pets.find((pet) => pet.id === ctx.currentPetId)?.name
          ),
        choosePrepackage: (ctx) =>
          Boolean(ctx.pets.find((pet) => pet.id === ctx.currentPetId)?.choosePrepackage),
        hasPrepackageVersionB: (context) => context.prepackageVersion === "B",
      },
    },

    (state) => {
      localStorage.setItem(FLOW_STATE_KEY, JSON.stringify(state))
    }
  )

  return (
    <GlobalStateContext.Provider value={subscriptionFlowService}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => {
  const globalState = useContext(GlobalStateContext)

  if (!globalState) {
    throw new Error("useGlobalState has to be used within <GlobalStateProvider.Provider>")
  }

  const context = useSelector(globalState, (state) => state.context)
  const send = globalState.send

  const validPets = useSelector(globalState, (state) => {
    const pets = state.context.pets.filter((pet) => !pet.isPetDraft)
    return pets
  })

  const hasMultiplePets = validPets.length > 1

  function getCurrentPet() {
    if (!context.currentPetId || context.pets.length === 0) {
      return null
    }

    return context.pets.find((pet) => pet.id === context.currentPetId)
  }

  return {
    context,
    send,
    globalState,
    helpers: {
      getCurrentPet,
      hasMultiplePets,
      validPets,
    },
  }
}
